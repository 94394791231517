import React from "react";
import BentoLandingPage from "../../component/LandingPage/BentoLandingPage";
import PricingLanding from "../../component/LandingPage/PricingLanding";
import CarrouselLanding from "../../component/LandingPage/CarrouselLanding";
import CoverageRecap from "../../component/LandingPage/CoverageRecap";

import "./welcome.css";

const Welcome = () => {
  return (
    <div id="landing-container">
      <CoverageRecap/>
      <BentoLandingPage />
      <PricingLanding />
      <CarrouselLanding /> 
    </div>
  );
};
export default Welcome;
