import React from "react";
import "./Popup.css";

export default function Popup({ text, closePopup }) {
  return (
    <div className="popup-container">
     <div className="popup-body">
      <div className="close" onClick={closePopup} data-testid="closePopup">
        X
      </div>
      <div className="text-popup">{text}</div>
      {/* <button onClick={closePopup}>Close </button> */}
     </div>
    </div>
  );
};