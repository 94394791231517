import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LocauxDisplayAdd from '../LocauxDisplayAdd/LocauxDisplayAdd';
import LocauxElem from '../LocauxElem/LocauxElem';
import BillsContrats from '../BillsContrats/BillsContrats';
import LocalPopupContent from '../LocalPopup/LocalPopupContent';
import UniversalModal from '../UniversalModal/UniversalModal';
import './Home.css';
import BillsDisplay from '../BillsDisplay/BillsDisplay';
import ContractDisplay from '../ContractDisplay/ContractDisplay';

const Home = ({openModalForTuto, pauseJoyride, resumeJoyride}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [selectedLocal, setSelectedLocal] = React.useState({});
  const [locaux, setLocaux] = React.useState([]);
  const location = useLocation();
  const { localId } = location.state || {};
  const [localRequests, setLocalRequests] = React.useState([]);
  const [selectedRequest, setSelectedRequest] = React.useState(false);

  const [selectedBill, setSelectedBill] = React.useState(null);
  const [viewBill, setViewBill] = React.useState(false);
  const [selectedContract, setSelectedContract] = React.useState(null);
  const [viewContract, setViewContract] = React.useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const seeBill = (bill) => {
    setSelectedBill(bill);
    setViewBill(true);
    window.scrollTo(0, 0);
  };
  const seeContract = (contract) => {
    setSelectedBill(contract);
    setViewBill(true);
  };
  const closeContract = () => {
    setSelectedBill(null);
    setViewBill(false);
  };
  const closeBill = () => {
    setViewBill(false);
    setSelectedBill(null);
  };
  useEffect(() => {
    if (localRequests.length == 0) {
      setSelectedRequest(false);
    }
  }, [localRequests]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    refreshDatas();
    if (localStorage.getItem("needTutorial") === "true") {
      resumeJoyride();
    }
  };
  const getLocaux = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    result = await result.json();
    console.log(result);
    setLocaux(result);
    if (localId) {
      setSelectedLocal(result.find((local) => local.id === localId));
    }
  };
  const getLocalRequests = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicileRequest/contact", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    result = await result.json();
    console.log(result);
    setLocalRequests(result);
  };
  const refreshDatas = () => {
    getLocaux();
    getLocalRequests();
  };
  useEffect(() => {
    refreshDatas();
  }, []);
  useEffect(() => {
    if (localRequests.length == 0) {
      setSelectedRequest(false);
    }
  }, [localRequests]);
  const CreateLocal = () => {
    refreshDatas();
    setSelectedRequest(false);
    if (localStorage.getItem("needTutorial") === "true") {
      console.log(locaux[0]);
      setSelectedLocal(locaux[0]);
    }
    setSelectedLocal({});
    setEditable(false);
    handleCloseModal();
    handleOpenModal();
  }
  useEffect(() => {
    console.log(openModalForTuto);
    if (openModalForTuto) {
      openModalForTuto.current = handleOpenModal;
    }
  }, [openModalForTuto]);
  const EditLocal = () => {
    refreshDatas();
    setEditable(true);
    handleOpenModal();
  }
  const SelectLocal = (local) => {
    setSelectedRequest(false);
    setSelectedLocal(local);
  }
  const SelectRequest = () => {
    setSelectedLocal({});
    setSelectedRequest(true);
  }
  const refuseLocalInvit = async (domicileId) => {
    let result = await fetch("https://api.coverage-eip.com/domicileRequest/decline/" + domicileId, {
        method: "POST",
        body: JSON.stringify({
          domicileId : domicileId,
        }),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    
    if (result.status === 200) {
        result = result.json().then((data) => {
          alert(data.msg);
        });
    } else {
        alert(result.statusText);
    }
    alert("La demande de collaboration à été refusée");
    refreshDatas();
  };
  const acceptLocalInvit = async (domicileId) => {
      let result = await fetch("https://api.coverage-eip.com/domicileRequest/accept/" + domicileId, {
          method: "POST",
          body: JSON.stringify({
            domicileId : domicileId,
          }),
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
          },
      });
      if (result.status === 200) {
          result = result.json().then((data) => {
              alert(data.msg);
          });
      } else {
          alert(result.statusText);
      }
      refreshDatas();
  };
  return (
    <>
    {viewBill && (
      <BillsDisplay bill={selectedBill} closeBill={closeBill}/>
    )}
    {viewContract && (
      <ContractDisplay bill={selectedBill} closeBill={closeContract}/>
    )}
    {!viewBill && !viewContract && (
    <div className='home-global-container'>
        <div className='title-page-locaux'>Locaux</div>
        <LocauxDisplayAdd Locaux={locaux} CreateLocal={CreateLocal} SelectLocal={SelectLocal} localId={localId} localRequests={localRequests} SelectRequest={SelectRequest} reqView={selectedRequest}/>
        {!selectedRequest && (
          <>
            <LocauxElem local={selectedLocal} editLocal={EditLocal}/>
            <BillsContrats local={selectedLocal} seeBill2={seeBill} seeContract2={seeContract}/>
          </>
        )}
        {selectedRequest && (
          <>
            <div id=''>Invitations</div>
            <div>{localRequests.length} Nouvelles demandes</div>
            <div className='container-request-collab'>
              {localRequests && localRequests.map((request) => {
                return (
                    <div className='request-collab' key={request.id}>
                      <div className='request-collab-pic-txt'>
                        {request.domicileId.photo && <img src={request.domicileId.photo} alt="" />}
                        {!request.domicileId.photo && <div className='request-collab-black-pic'></div>}
                        <div className='title-txt-request-card'>
                          <div className='request-collab-name'>{request.userId.firstName + " " + request.userId.lastName}</div>
                          <div className='request-collab-text'>John Doe vous a envoyé une invitation pour rejoindre {request.domicileId.domicileName}</div>
                        </div>
                      </div>
                      <div className='request-collab-buttons'>
                        <div className='request-collab-button-decline' onClick={() => refuseLocalInvit(request.id)}>Décliner<img src='decline.svg'/></div>
                        <div className='request-collab-button-accept' onClick={() => acceptLocalInvit(request.id)}>Rejoindre<img src='join.svg'/></div>
                      </div>
                    </div>
                )
              })}
            </div>
          </>
        )}
        <UniversalModal isOpen={isModalOpen} onClose={handleCloseModal}>
          <LocalPopupContent
            state={isModalOpen}
            onClose={handleCloseModal}
            editable={editable}
            initialValues={editable ? selectedLocal : {}}
          />
        </UniversalModal>
    </div>
    )}
    </>
  );
};

export default Home;
