import React, { useState } from 'react';
import './BillsContrats.css';
import ContratImportAndDisplay from '../ContratImportAndDisplay/ContratImportAndDisplay';
import BillsImportAndDisplay from '../BillsImportAndDisplay/BillsImportAndDisplay';
const BillsContrats = ({local, seeBill2, seeContract2}) => {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const seeBill = (bill) => {
    seeBill2(bill);
  };
  const seeContract = (contract) => {
    seeBill2(contract);
  };

  return (
    <div className='container-global-list-bills-contrats'>
        {local !== undefined && local.domicileName && (
        <div className='container-list-bills-contrats'>
          <div className='container-tab-list-bills-contrats'>
            <div
              className={`button-tab-contrats ${activeTab === 1 ? 'button-tab-selected' : ''}`}
              onClick={() => handleTabChange(1)}
              tabIndex={activeTab === 1 ? '0' : '-1'}
            >
              Contrats
            </div>
            <div
              className={`button-tab-bills ${activeTab === 2 ? 'button-tab-selected' : ''}`}
              onClick={() => handleTabChange(2)}
              tabIndex={activeTab === 2 ? '0' : '-1'}
            >
              Factures
            </div>
          </div>
          {activeTab === 1 && (
            <div>
              <ContratImportAndDisplay actualDomicileId={local.id} local={local}/>
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <BillsImportAndDisplay seeBill={seeBill} domicileId={local.id} local={local}/>
            </div>
          )}
        </div>
        )}
      {local !== undefined && !local.domicileName && (
        <div id='no-selected-local-bills-contracts'>
          Pas de local sélectionné
        </div>
      )}
    </div>
  );
};

export default BillsContrats;
