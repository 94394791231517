import React from 'react';
import './ContractDisplay.css';

const ContractDisplay = ({contract, closeDisplay}) => {
    const [contractVersion, setcontractVersion] = React.useState([]);
    console.log(contract);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("fr-FR");
    };
    return (
        <div className='contract-display-view'>
            <div className='container-contract-display'>
                <div className='contract-left-part'>
                    <div id='container-title-first-section-contract'>
                        <div id='title-first-section-contract'>
                            <img src="doc-contract-dashboard.svg" alt="" />Tableau de bord contrat
                        </div>
                        {/* <div id='edit-button-contrat'>
                            Editer <img src="pen-edit.svg" alt="" />
                        </div> */}
                        <div id='edit-button-contrat' onClick={() => closeDisplay()}>
                            Retour
                        </div>
                    </div>
                    <div className='first-section-contract-display'>
                        <p>{contract.name}</p>
                        <div>
                            <img src="purple-contract.svg" alt="" />
                            <p>
                   Date de début:{" "}
                   {contract.date
                    ? formatDate(contract.date)
                    : "Non spécifié"}
                </p>
                <p>
                  Date de fin:{" "}
                  {contract.endDate
                    ? formatDate(contract.endDate)
                    : "Non spécifié"}
                </p>
                <p>Type: {contract.type || "Non spécifié"}</p>
                            {/* <div id='delete-version-button-contrat-view'>Supprimer cette version<img src="clock-delete.svg" alt="" /></div> */}
                            <div id='dwl-button-contrat-view'  onClick={() => {window.open(contract.image[0].url, '_blank')}}>Telecharger cette version<img src="white-clock.svg" alt="" /></div>
                        </div>
                    </div>
                    <div className='second-section-contract-display'>
                        <span>
                            Résumé
                        </span>
                        <div className='second-section-contract-display-content'>
                            <div id='resumate-contract'>{contract.summary}</div>
                            {/* <div id='reneragete-button'>Regénérer le résumé <img src="regenerate.svg" alt="" /></div> */}
                        </div>
                    </div>
                </div>
                {/* <div className='contract-right-part'>
                    <div id='container-title-right-part-contract-view'>
                        <img src="purple-clock.svg" alt="" />
                        Historique des contrats
                    </div>
                    <div id='container-history-contract'>
                        <div className='version-contract-history-container'>
                            <img src="doc-contract-dashboard.svg" alt="" />
                            <div className='txt-history-contract-version'>
                                Contrat d'habitation 2023
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ContractDisplay;