import React from "react";
// import TitlePage from "../../component/TitlePage/TitlePage";
import "./Contract.css";
import { useState, useEffect } from "react";
// import Popupbis from "../../component/Popupbis/Popupbis";
// import FilesUploader from "../../component/FilesUploader/FilesUploader";
// import ContractElem from "./ContractElem";
import ContractDisplay from "../../component/ContractDisplay/ContractDisplay";

const Contract = () => {
  // const [open, setOpenPopup] = useState(false);
  // const [contracts, setContracts] = useState([]);
  // const [search, setSearch] = useState("");
  // const [tab, setTab] = useState("");
  // const [isOnlyFavorite, setIsOnlyFavorite] = useState(false);
  // const [selectedTypes, setSelectedTypes] = useState([]);
  const [insurancesList, setInsurancesList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contractType, setContractType] = useState("");
  const [contractName, setContractName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [insuranceSummary, setInsuranceSummary] = useState("");
  const [domicileName, setDomicileName] = useState("");
  //const [domicileId, setDomicileId] = useState("");
  const [Locaux, setLocaux] = React.useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [insuranceToUpload, setInsuranceToUpload] = useState(null);
  var insuranceToAnalyze = "";
  const [seeDetails, setSeeDetails] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Date de début :", startDate);
    console.log("Date de fin :", endDate);
    console.log("Type de contrat :", contractType);
    console.log("Nom du contrat :", contractName);
    console.log("Nom du domicile :", domicileName);
    // insuranceSummary.response = insuranceSummary.response.replace(/"/g, "");
    console.log("Résumé du contrat:", insuranceSummary.summary);
    console.log(insuranceToUpload);
    addInsurances();
  };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("fr-FR");
  // };

  const getLocaux = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    //result = await result.json();
    //console.log(result);
    //setLocaux(result);
    result.json().then((data) => {
      const Locaux = data.map((item) => ({
        id: item.id,
        domicileName: item.domicileName,
      }));
      setLocaux(Locaux);
      console.log(Locaux);
    });
  };

  const putInsurances = async (historyId, contractId) => {
    const temp = Locaux.find(element => element.domicileName === domicileName);
    console.log(temp);
  
    let payload = {
      historyId: historyId,
      contractId: contractId
    };
  
    if (temp) {
      payload.domicileId = temp.id;
      console.log(temp.id);
    }
  
    let result = await fetch("https://api.coverage-eip.com/contracts", {
      method: "Put",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  
    if (result.status === 200) {
      alert("Contrat importé avec succés");
      window.location.reload();
    } else {
      alert("Erreur lors de l'ajout au domicile. Le contrat a bien été importé mais il n'est pas relié à un domicile.");
    }
  };

  const addInsurances = async () => {
    let result = await fetch("https://api.coverage-eip.com/contracts", {
      method: "Post",
      body: JSON.stringify({
        startDate: startDate,
        type: contractType,
        name: contractName,
        insuranceName: insuranceName,
        data: insuranceToUpload,
        endDate: endDate,
        //summary: insuranceSummary.summary,
        summary: "OK",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      result = result.json().then((data) => {
        console.log(data);
        console.log(data.id);
        console.log(data.contracts[0].id);
        //alert("Contrat importé avec succés");
        putInsurances(data.id, data.contracts[0].id);
      });
      //window.location.reload();
    } else {
      alert("erreur lors de l'importation du contrat");
    }
  };

  const getInsurances = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/contracts/allContracts",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      console.log(result);
      result.json().then((data) => {
        const insurances = data.map((item) => ({
          id: item.id,
          domicileId: item.domicileId,
          //     historyId: item.history.id,
          contracts: item.contracts.map((contract) => ({
            id: contract.id,
            name: contract.name,
            startDate: contract.startDate,
            endDate: contract.endDate,
            type: contract.type,
            summary: contract.summary,
            ownerId: contract.ownerId,
            //       history: item.history,
            //       image: contract.images,
          })),
        }));
        console.log(data);
        setInsurancesList(insurances);
      });
    } else {
      console.error("Failed to fetch data:", result.status);
      return;
    }
  };

  useEffect(() => {
    getInsurances();
    getLocaux();
    // eslint-disable-next-line
  }, []);

  const analyzeInsurances = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/insurances/analyze",
      {
        method: "post",
        body: JSON.stringify({
          data: insuranceToAnalyze,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (result.status === 200) {
      console.log(result);
      result = result.json().then((data) => {
        setInsuranceSummary(data);
      });
    } else {
      return;
    }
  };

  const chooseFileToAnalyze = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const base64 = await convertToBase64(file);
      setInsuranceToUpload(base64);
      insuranceToAnalyze = base64;
      analyzeInsurances();
    } else {
      alert("Veuillez sélectionner un fichier PDF.");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  // const updateFavorite = (id) => {
  //   let tamp = contracts.map((each) => {
  //     if (each.id === id) {
  //       each.isFavorite = !each.isFavorite;
  //     }
  //     return each;
  //   });
  //   setContracts(tamp);
  //   setTab(sortTab(tamp));
  // };

  // const sortTab = (tab) => {
  //   return tab.sort((a, b) => {
  //     const nameA = a.name || "";
  //     const nameB = b.name || "";

  //     if (a.isFavorite === b.isFavorite) {
  //       return nameA.localeCompare(nameB);
  //     }
  //     return b.isFavorite - a.isFavorite;
  //   });
  // };

  // const updateContrat = (newContract) => {
  //   let tamp = contracts.map((each) => {
  //     if (each.id === newContract.id) {
  //       each = newContract;
  //     }
  //     return each;
  //   });
  //   setContracts(tamp);
  //   setTab(sortTab(tamp));
  // };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Voulez-vous vraiment supprimer ce contrat?"
    );
    if (confirmDelete) {
      deleteInsurance(id);
    }
  };

  const deleteInsurance = async (id) => {
    const response = await fetch(
      `https://api.coverage-eip.com/contracts/full/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.ok) {
      alert("Contrat supprimé avec succès.");
      window.location.reload();
    } else {
      alert(`Échec de la suppression du contrat : ${response.status}`);
      //window.location.reload();
    }
  };

  const showDetails = (contract) => {
    setSelectedContract(contract);
    setSeeDetails(true);
  };

  const handleDownload = (url) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "true");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("URL non spécifiée pour le téléchargement.");
    }
  };
  return (
    <>
      {seeDetails && (
        <ContractDisplay
          contract={selectedContract}
          handleDownload={handleDownload}
          closeDisplay={() => setSeeDetails(false)}
        />
      )}
      {!seeDetails && (
        <div id="container-contract-page">
          <span id="title-contract-page">Contract</span>
          <div id="container-upload-display-contracts">
            <div id="container-upload-contracts">
              <input
                type="file"
                onChange={chooseFileToAnalyze}
                accept="application/pdf"
                style={{ display: "none" }}
                id="fileInput"
              />
              <div
                onClick={() => document.getElementById("fileInput").click()}
                id="upload-contract-data"
              >
                Ajouter un contrat PDF
              </div>
              {insuranceSummary && (
                <div>
                  Remplissez les informations ci dessous pour ajouter votre
                  contrat
                </div>
              )}
              {!insuranceSummary && <div>Veillez importer votre contract</div>}

              <div className="form-edit-date-infos-contract">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>
                      Date de début du contrat:
                      <input
                        className="input-edit-infos-contract"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        readOnly={!insuranceSummary}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Date de fin du contrat:
                      <input
                        className="input-edit-infos-contract"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        readOnly={!insuranceSummary}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Nom de l'assureur :
                      <input
                        className="input-edit-infos-contract"
                        type="text"
                        value={insuranceName}
                        onChange={(e) => setInsuranceName(e.target.value)}
                        readOnly={!insuranceSummary}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Type de contrat:
                      <input
                        className="input-edit-infos-contract"
                        type="text"
                        value={contractType}
                        onChange={(e) => setContractType(e.target.value)}
                        readOnly={!insuranceSummary}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Nom du contrat:
                      <input
                        className="input-edit-infos-contract"
                        type="text"
                        value={contractName}
                        onChange={(e) => setContractName(e.target.value)}
                        readOnly={!insuranceSummary}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Domicile du contrat:
                      <input
                        className="input-edit-infos-contract"
                        type="text"
                        value={domicileName}
                        onChange={(e) => setDomicileName(e.target.value)}
                        readOnly={!insuranceSummary}
                      />
                    </label>
                  </div>
                  <div id="container-send-data-contract-edit">
                    <button type="submit" id="send-data-contract-edit">
                      Soumettre
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div id="container-display-contracts">
              {insurancesList.length === 0 && (
                <>Pas de contrat pour le moment</>
              )}
              {insurancesList.length > 0 &&
                insurancesList.map((item, index) =>
                  item.contracts.map((contract) => (
                    <div className="card-contrat-pres-container" key={index}>
                      <div className="icon-title-contract">
                        <img src="purple-contract.svg" alt="" />
                        <div>{`Nom : ${contract.name}`}</div>
                      </div>
                      <div id="button-see-contract-container">
                        <div
                          id="button-delete-contrat-infos"
                          onClick={() => handleDelete(item.id)}
                        >
                          Supprimer
                        </div>
                        <div
                          id="button-redirect-contrat-infos"
                          onClick={() => showDetails(contract)}
                        >
                          Gérer mon contrat
                        </div>
                      </div>
                    </div>
                  ))
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contract;
