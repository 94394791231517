import React, {useEffect, useState} from 'react';
import './LocauxElem.css';

const LocauxElem = ({local, editLocal}) => {
  const [AllContact, setAllContact] = useState([]);
  const [collabs, setCollabs] = useState([]);

  const getListContacts = async () => {
        let result = await fetch("https://api.coverage-eip.com/contacts", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (result.status === 200) {
            result = result.json().then((data) => {
                // setCollabs(data.filter((contact) => contact.domicileId === local.id));
                setAllContact(data);
            });
        } else {
        }
    };
  const getInfosLocal = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile/"+local.id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    });
    result = await result.json();
  }
  useEffect(() => {
    console.log(local);
    if (local && local !== null && local !== undefined && local.tenant && local.tenant.length > 0) {
      let tamp = [];
      local?.tenant.forEach((collab) => {
        AllContact.forEach((contact) => {
          if (contact.id === collab.id) {
            tamp.push(contact);
          }
        });
      });
      setCollabs(tamp);
    } else {
      setCollabs([]);
    }
  }, [local]);
  useEffect(() => {
    getListContacts();
  }, []);
  const HandleEditLocal = () => {
    editLocal();
  }
  const deleteLocal = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile/"+local.id, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          domicileId: local.id
        }),
    });
    result = await result.json();
    window.location.reload();
  }
  return (
    <>
    <div id="container-local-infos">
        {local !== undefined && !local.domicileName && (
          <>
            <div id="section-left-local-infos" className='no-local-selected'>
              Pas de local sélectionné
            </div>
            <div id="section-right-local-infos" className='no-local-selected'>
              Pas de local sélectionné
            </div>
          </>
      )}
      {local?.domicileName && (
        <>
        <div id="section-left-local-infos">
          <div className='container-global-domicile-elem'>
            <div className='container-domicile-elem'>
              <div className='local-container-elem' onClick={HandleEditLocal}>
                {!local.photo && (<div id='black-pic-local-details'></div>)}
                {local.photo && (<img src={local.photo.url} id='local-pic-app-details' />)}
                <img src="appareil-photos.svg" className='logo-appareil-elem-local' alt="" />
                <div className='edit-text-pic-local'>Modifier</div>
              </div>
              <div className='info-local-container-elem'>
                <div className='title-info-local'>{local.domicileName}</div>
                <div className='addresse-info-local txtsize'> <img src="purple-pin.svg" className='icon-illustration-card-local' alt="" /> {local.address}</div>
                <div className='nbcolab-info-local txtsize'> <img src="group-people.svg" className='icon-illustration-card-local' alt="" /> {local.tenant.length} Collaborateurs</div>
                <div className='local-double-infos'>
                  <img src="purple-files.svg" alt="" />
                  <div className='nbcontrat-info-local txtsize'>{local.contracts.length} Contrats</div>
                  <div className='nbcontrat-info-local txtsize'>/</div>
                  <div className='nbfacture-info-local txtsize'>{local.bills.length} Factures</div>
                </div>
                {/* <div className='money-info-local txtsize'>45 660€ / 90 000</div> */}
              </div>
              <div id="cirlce-cover-container">
                <div className='circle-calcul-coverage'>0,00%</div>
              </div>
            </div>
          </div>
          <div className='buttons-local-container'>
            {/* <div className='edit-button-local txtsize' onClick={HandleEditLocal}>Modifier le local<img src='purple-pen.svg'/></div> */}
            <div className='container-button-leave-delete'>
              <div className='button-local-option button-leave-local txtsize' onClick={deleteLocal}>Quitter le local<img src='purple-exit.svg'/></div>
              <div className='button-local-option button-edit-local txtsize' onClick={HandleEditLocal}>Modifier le local<img src='white-pen.svg'/></div>
              {/* <div className='delete-button-local txtsize' onClick={deleteLocal}>Supprimer<img src='red-bin.svg'/></div> */}
            </div>
          </div>
        </div>
        <div id="section-right-local-infos" className='no-local-selected'>
          {collabs.length > 0 && (
            <>
              <div className='container-collab-local'>
                {collabs.map((collab, index) => (
                  <div className='collab-local' key={index}>
                    <img src={collab.profilePicture} className='pic-collab-local' />
                    <div className='name-collab-local'>{collab.firstName} {collab.lastName}</div>
                  </div>
                ))}
              </div>
            </>
          )}
          {collabs.length === 0 && (
            <>
              <div className='container-collab-local'>
                <div className='no-collab-selected'>Pas de collaborateurs sélectionnés</div>
              </div>
            </>
          )}
        </div>
      </>
      )}
    </div>
    </>
  );
};

export default LocauxElem;
