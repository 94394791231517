import React, { useState, useEffect, useRef } from 'react';
import './Popupbis.css';
const Popupbis = ({ state, children, onClose, large = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };
  useEffect(() => {
    setIsOpen(state);
    if (state) {
      document.body.classList.add('popup-centered');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('popup-centered');
      document.body.style.overflow = 'auto';
    }
  }, [state]);

  return (
    <>
      {isOpen && (
        <div ref={popupRef} className={`popup ${large ? 'large-popup' : ''}`}>
          <div className="popup-content">
            <img className="cross-popup" src="cross.svg" alt="Icon cross" onClick={handleClose}/>
                {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Popupbis;