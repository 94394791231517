import './SideMenu.css';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SideMenu = () => {
    const [activePage, setActivePage] = useState('Home');
    const [infosProfil, setInfosProfil] = useState({});
    const generateBackgroundColor = (fname, lname) => {
        const initials = `${fname?.charAt(0)}${lname?.charAt(0)}`;
        const colors = ['#ff7f0e', '#2ca02c', '#1f77b4', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
        const hashCode = initials.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
        const colorIndex = hashCode % colors.length;
        return colors[colorIndex];
    };
    const navigate = useNavigate();
    const location = useLocation();
    const getInfosProfil = async () => {
        let result = await fetch("https://api.coverage-eip.com/account", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        result = await result.json();
        setInfosProfil(result);
        console.log(result);
    };
    useEffect(() => {
        getInfosProfil();
    }, []);
    const handleButtonClick = (pageName) => {
        navigate(`/${pageName}`);
    };
    useEffect(() => {
        const path = location.pathname.split('/')[1];
        setActivePage(path);
    }, [location.pathname]);
    // useEffect(() => {
    //     setActivePage(history.location.pathname.split('/')[1]);
    // }, [history.location.pathname]);
    // useEffect(() => {
    //     const unlisten = history.listen((location) => {
    //       const path = location.pathname.split('/')[1];
    //       setActivePage(path);
    //     });
    //     return () => {
    //       unlisten();
    //     };
    //   }, []);

    return (
        <div className="sidebarmenu">
            <div className="pic-container-sidebar">
                {infosProfil.profilePicture && <img src={infosProfil.profilePicture} alt="side-menu" className="profile-pic" />}
                {!infosProfil.profilePicture && <div style={{
                    backgroundColor: generateBackgroundColor(infosProfil.firstName, infosProfil.lastName),
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '3rem',
                }}>
                    {infosProfil.firstName?.charAt(0).toUpperCase()}{infosProfil.lastName?.charAt(0).toUpperCase()}
                </div>
                }
            </div>
            <div className='name-profile-sidebar'>{infosProfil.firstName} {infosProfil.lastName}</div>
            <div className='button-edit-profile-sidebar' onClick={() => navigate('/Profile')}>
                Modifier le profil
            </div>
            <div className="line"></div>
            <div className={`button-dashboard-sidebar ${activePage === 'Dashboard' ? 'button-sidebar-active' : 'button-sinactive'}`} onClick={() => handleButtonClick('Dashboard')}>
                Dashboard
                <img className="" src="dashboard.svg" alt="Icon Dashboard"/>
            </div>
            <div className={`button-locaux-sidebar ${activePage === 'Home' ? 'button-sidebar-active' : 'button-sinactive'}`} onClick={() => handleButtonClick('Home')}>
                Locaux
                <img className="" src="white-house.svg" alt="Icon House"/>
            </div>
            <div className={`button-assurances-sidebar ${activePage === 'Contract' ? 'button-sidebar-active' : 'button-sinactive'}`} onClick={() => handleButtonClick('Contract')}>
                Mes assurances
                <img className="" src="white-contrat.svg" alt="Icon House"/>
            </div>
            <div className={`button-collaborateurs-sidebar ${activePage === 'Collaborators' ? 'button-sidebar-active' : 'button-sinactive'}`} onClick={() => handleButtonClick('Collaborators')}>
                Collaborateurs
                <img className="" src="white-people.svg" alt="Icon House"/>
            </div>
        </div>
    );
};

export default SideMenu;