import React, { useState } from 'react';
import './Carousel.css'; // Style du carrousel

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  return (
    <div id="div-carousel-container">
    <img src="chevron-gauche.svg" id="prev-button" onClick={prevSlide} alt="" />
    <div id="carousel-container">
      <div id="carousel-wrapper" style={{ transform: `translateX(-${currentIndex * 33.3333}%)` }}>
        {items.map((item, index) => (
          <div key={index} className="carousel-slide">{item}</div>
        ))}
      </div>
    </div>
      <img src="chevron-right.svg" alt="" id="carrousel-next-button" onClick={nextSlide} />
    </div>
  );
};

export default Carousel;