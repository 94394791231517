import React, { useState, useEffect } from "react";
import "./Bills.css";
import TitlePage from "../../component/TitlePage/TitlePage";
import Favorite from "../../component/Favorite";
import { generateTab, sortTab } from "./BillsFunctions";
import Popupbis from "../../component/Popupbis/Popupbis";

const Bills = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState("");
  const [datas, setDatas] = useState("");
  const nbPage = Math.ceil(tab.length / 5);
  const [selectedFile, setSelectedFile] = useState(null);
  const [factures, setFactures] = useState([]);
  const [open, setOpenPopup] = useState(false);
  const [open2, setOpenPopup2] = useState(false);
  const [billAmount, setBillAmount] = useState("");
  var billID = 37;

  const closePopup = () => {
    setOpenPopup(false);
    window.location.reload();
  };

  async function DeleteBill(factureID) {
    let result = await fetch("https://api.coverage-eip.com/bill", {
      method: "delete",
      body: JSON.stringify({
        bill: factureID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      // alert("Facture supprimée avec succès");
      window.location.reload();
    } else {
      alert("Erreur lors de la suppression de la facture");
      return;
    }
  }

  useEffect(() => {
    getBills();
    // eslint-disable-next-line
  }, []);

  const getBills = async () => {
    let result = await fetch("https://api.coverage-eip.com/bills", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      result = result.json().then((data) => {
        setFactures(data);
      });
    } else {
      return;
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const validateBill =  async () => {
    let result = await fetch("https://api.dev.coverage-eip.com/Bill/validateBill", {
      method: "post",
      body: JSON.stringify({
        bill_id: billID,
        validation: true,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
    } else {
      return;
    }
  }

  const IsvalidBill = () => {

    return (
      <div>
        <p>Facture importée avec succès</p>
        <p>Le montant de votre facture est-il bien de {billAmount} € ?</p>
        <button className="import-buttons" onClick={closePopup}>Oui</button>
        <button className="import-buttons" onClick={closePopup}>Non</button>
      </div>
    )
  };

  const handleUpload = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const base64Data = event.target.result;
        let result = await fetch("https://api.coverage-eip.com/bill", {
          method: "post",
          body: JSON.stringify({
            pdf: base64Data,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (result.status === 200) {
          result = result.json().then((data) => {
            setBillAmount(data.amount);
            billID = billID + 1;
            setOpenPopup(true);
          });
          // alert("Facture importée avec succès");
        } else {
          alert("Echec de l'importation de la facture");
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert("Veuillez sélectionner un fichier");
    }
  };

  const changeSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value === "") {
      
      setTab(sortTab(datas));
      return;
    }
    let tamp = tab.filter((each) => each.Filename.includes(event.target.value));
    setTab(tamp);
  };
  useEffect(() => {
    const asyncFunc = async () => {
      let result = await fetch("https://api.coverage-eip.com/bills",  {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const data = await result.json();
      
      if (data.bills === undefined) {
        return;
      }
      let tamp = [];
      data.bills.forEach((element) => {
        
        const decodedData = window.atob(element.bill.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(decodedData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < decodedData.length; i++) {
          uint8Array[i] = decodedData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        tamp.push({
          id: element.id,
          Filename: "Facture n° " + element.id,
          photo: "pdf_adobe.jpg",
          url: url,
          isFavorite: element.favorite,
        });
        
        // Ou
        // window.location.href = blobUrl; // Pour télécharger automatiquement le PDF
        setDatas(tamp);
        setTab(sortTab(tamp));
      });
    };
    asyncFunc();
  }, []);

  async function editFavoriteApi(idBills, method) {
    let result = await fetch("https://api.coverage-eip.com/bills/favorite",  {
      method: method,
      body: JSON.stringify({
        "bill": idBills,
      }),
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    const data = await result.json();
    
  }
  const switchFavorite = (elem) => {
    for (let index = 0; index < tab.length; index++) {
      if (tab[index].id === elem.id) {
        if (tab[index].isFavorite === true) {
          editFavoriteApi(elem.id, "put");
        } else {
          editFavoriteApi(elem.id, "post");
        }
        tab[index].isFavorite = !tab[index].isFavorite;
      }
    }
    setTab(sortTab(tab));
  };
  const openFile = (elem) => {
    
    window.open(elem);
  };
  const downloadFile = (elem) => {
    const anchor = document.createElement("a");
    anchor.href = elem;
    anchor.download = "bills.pdf";
    anchor.click();
  };
  function ReturnBills({ elem }) {
    if (tab.length > 0) {
      return (
        <div>
          <div className="bills-content">
            <img src={elem.photo} className="file_logo" alt={elem.Filename} />
            <div className="file_name">{elem.Filename}</div>
            <div className="favorite-container">
              <Favorite
                isFavorite={elem.isFavorite}
                changeFavorite={switchFavorite}
                element={elem}
              />
            </div>
            {/* <img
            className="bill_Icon"
            src="bin.png"
            alt="Icon delete bill"
            onClick={DeleteBill}
          /> */}
          </div>
          <div className="favorite-container">
            <Favorite
              isFavorite={elem.isFavorite}
              changeFavorite={switchFavorite}
              element={elem}
            />
          </div>
          <div className="button-open-container">
            {/* <button className="open-button" onClick={() => openFile(elem.url)}>Voir</button> */}
            <img
              className="cross-popup"
              src="see.svg"
              alt="Icon cross"
              onClick={() => openFile(elem.url)}
            />
          </div>
          <div className="button-open-container">
            <img
              className="cross-popup"
              src="download.svg"
              alt="Icon cross"
              onClick={() => downloadFile(elem.url)}
            />
          </div>
        </div>
      );
    }
  }
  function pageBills() {
    let tamp = [];
    for (let index = 0; index < 5; index++) {
      if (tab[index + (page - 1) * 5] !== undefined) {
        tamp.push(tab[index + (page - 1) * 5]);
      }
    }
    return tamp;
  }
  function EmptyList() {
    if (tab.length < 1 || tab === undefined) {
      return <div className="no-bills">Vous n'avez pas encore de facture</div>;
    }
  }
  const nextPage = () => {
    if (page < nbPage) {
      setPage(page + 1);
    }
  };
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  function Pagination() {
    if (tab.length > 5) {
      return (
        <div className="pagination">
          <div className="page">
            Page {page} / {nbPage}
          </div>
          <div className="page-button">
            <div
              className="page-button-left"
              onClick={prevPage}
              data-testid="prev-button"
            >
              &lt;
            </div>
            <div
              className="page-button-right"
              onClick={nextPage}
              data-testid="next-button"
            >
              &gt;
            </div>
          </div>
        </div>
      );
    }
  }
  function seeOnlyFavorite() {
    let tamp = tab.filter((each) => each.isFavorite === true);
    setTab(tamp);
  }
  function resetFavorite() {
    setTab(sortTab(datas));
  }
  return (
    <div className="container-bills" data-testid="bills">
      <TitlePage TitlePage="Facture" />
      <div className="container-bills-content">
        <div className="options"></div>

        <div>
          <ul>
            {factures.map((facture) => (
              
              <li key={facture.id}>
                <div className="bills_text">
                  <strong>ID de la Facture:</strong> {facture._id}
                  <br />
                  <strong>Montant: </strong> {facture.amount}
                  <br />
                  <div className="product-buttons">
                    <div className="bills-add-container">
                      <div className="bills-add-text">Ajouter un produit</div>
                    </div>
                    <div className="bills-add-container">
                      <div className="bills-add-text">Supprimer un produit</div>
                    </div>
                  </div>
                </div>
                <img
                  className="bill_Icon"
                  src="bin.png"
                  alt="Icon delete bill"
                  onClick={() => DeleteBill(facture.id)}
                />
              </li>
            ))}
          </ul>
        </div>
        <div>
          <input
            type="file"
            className="chooseFile-buttons"
            accept=".pdf"
            id="customFileInput"
            onChange={handleFileChange}
          />
          <button className="import-buttons" onClick={handleUpload}>
            Importer une facture
          </button>
          <Popupbis
            state={open}
            onClose={closePopup}
            children={IsvalidBill()}
          ></Popupbis>
        </div>
      </div>
    </div>
  );
};

export default Bills;
