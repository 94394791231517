import React, { useState, useEffect, useRef } from "react";
import "./Profile.css";

function Profile(props) {
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [editProfile, seteditProfile] = useState(false);
  const [newlastname, setNewLastname] = useState("");
  const [newfirstname, setNewFirstname] = useState("");
  const [newemail, setNewEmail] = useState("");
  const [newphone, setNewPhone] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [numberContract, setNumberContract] = useState("0");
  const [numberLocaux, setNumberLocaux] = useState("0");
  const [numberbills, setNumberBills] = useState("0");
  const [newpasswordconfirm, setNewPasswordConfirm] = useState("");
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const telRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordconfirmRef = useRef(null);

  const getProfile = async () => {
    let result = await fetch("https://api.coverage-eip.com/account", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      result = result.json().then((data) => {
        setLastname(data.lastName);
        setFirstname(data.firstName);
        setEmail(data.email);
        setPhone(data.phoneNumber);
        setAvatar(data.profilPicture);
      });
    } else {
      alert(result.msg);
      return;
    }
  };

  const deleteAccount = async () => {
    let result = await fetch("https://api.coverage-eip.com/account/", {
      method: "delete",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      localStorage.clear();
      window.location = "/";
    } else {
      alert(result.statusText);
    }
  };

  const updateprofil = async () => {
    if (newpassword === "") {
      let result = await fetch("https://api.coverage-eip.com/account/", {
        method: "put",
        body: JSON.stringify({
          firstName: newfirstname,
          lastName: newlastname,
          email: newemail,
          phoneNumber: newphone,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (result.status === 200) {
        window.location.reload();
        alert("Vos informations de profil ont bien été modifiées");
      } else {
        alert(result.msg);
        window.location.reload();
      }
    } else {
      if (newpassword !== newpasswordconfirm) {
        alert("Les mots de passe ne correspondent pas.");
        return;
      }
      if (
        !newpassword.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/g
        )
      ) {
        alert(
          "Le mot de passe doit contenir une majuscule, une minuscule, un chiffre, un caractère spécial et 8 caractères au minimum."
        );
        return;
      }
      let result = await fetch("https://api.coverage-eip.com/account/", {
        method: "put",
        body: JSON.stringify({
          firstName: newfirstname,
          lastName: newlastname,
          email: newemail,
          password: newpassword,
          phoneNumber: newphone,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (result.status === 200) {
        window.location.reload();
        alert("Vos informations de profil ont bien été modifiées");
      } else {
        alert(result.msg);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    getProfile();
    getInsurances();
    getBills();
    getLocaux();
    // eslint-disable-next-line
  }, []);

  const geteditProfile = () => {
    setNewFirstname(firstname);
    setNewLastname(lastname);
    setNewPhone(phone);
    setNewEmail(email);
    seteditProfile(!editProfile);
    setNewPassword("");
    setNewPasswordConfirm("");
    return;
  };

  const handleSave = () => {
    const text0 = firstnameRef.current.textContent;
    setNewFirstname(text0);
    const text1 = lastnameRef.current.textContent;
    setNewLastname(text1);
    const text2 = emailRef.current.textContent;
    setNewEmail(text2);
    const text3 = telRef.current.textContent;
    setNewPhone(text3);
    const text4 = passwordRef.current.textContent;
    setNewPassword(text4);
    const text5 = passwordconfirmRef.current.textContent;
    setNewPasswordConfirm(text5);
  };

  function openFileExplorer() {
    document.getElementById("profileInput").click();
  }

  function handleFileChange() {
    const fileInput = document.getElementById("profileInput");
    const file = fileInput.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setAvatar(e.target.result);
        document.getElementById("currentProfilePic").src = avatar;
      };
      reader.readAsDataURL(file);
    }
  }

  const getLocaux = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/domicile/",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      result.json().then((data) => {
        setNumberLocaux(data.length)
      });
    } else {
      console.error("Failed to fetch data:", result.status);
      return;
    }
  };

  const getBills = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/Bills/",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      result.json().then((data) => {
        setNumberBills(data.length)
      });
    } else {
      console.error("Failed to fetch data:", result.status);
      return;
    }
  };

  const getInsurances = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/contracts/allContracts",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      result.json().then((data) => {
        setNumberContract(data.length)
      });
    } else {
      console.error("Failed to fetch data:", result.status);
      return;
    }
  };

  const getProfilPage = () => {
    if (editProfile) {
      return (
        <>
          <div className="left-profil-container">
            <img
              src="chemin-vers-votre-image.jpg"
              alt="profil-pic"
              className="profile-pic"
              id="profilePic"
              onClick={openFileExplorer}
            />
            <input type="file" id="profileInput" accept="image/*" />
            <div className="locaux-contrats-container">
              <div className="locaux-contrats-text">{numberLocaux} locaux</div>
              <img
                src="house.svg"
                alt="house-pic"
                className="locaux-contrats-pic"
              />
            </div>
            <div className="locaux-contrats-container">
              <div className="locaux-contrats-text">{numberContract} contrats</div>
              <img
                src="file.png"
                alt="file-pic"
                className="locaux-contrats-pic"
              />
            </div>
            <div className="locaux-contrats-container">
              <div className="locaux-contrats-text">{numberbills} factures</div>
              <img
                src="file.png"
                alt="file-pic"
                className="locaux-contrats-pic"
              />
            </div>
            <div className="edition-profil-container" onClick={geteditProfile}>
              Editer
            </div>
            <div className="delete-profil-container" onClick={deleteAccount}>
              Supprimer votre compte
            </div>
          </div>
          <div className="vertical-line"></div>
          <div className="right-profil-container">
            <div className="info-profil-container">
              <div className="title-info-profil">Prénom :</div>
              <div
                contenteditable="true"
                className="info-profil"
                ref={firstnameRef}
                onBlur={handleSave}
              >
                {newfirstname}
              </div>
              <div className="title-info-profil">Nom :</div>{" "}
              <div
                contenteditable="true"
                className="info-profil"
                ref={lastnameRef}
                onBlur={handleSave}
              >
                {newlastname}
              </div>
              <div className="title-info-profil">Tel :</div>
              <div
                contenteditable="true"
                className="info-profil"
                ref={telRef}
                onBlur={handleSave}
              >
                {newphone}
              </div>
              <div className="title-info-profil">Mail :</div>
              <div
                contenteditable="true"
                className="info-profil"
                ref={emailRef}
                onBlur={handleSave}
              >
                {newemail}
              </div>
              <div className="title-info-profil">Mot de passe :</div>
              <div
                contenteditable="true"
                className="info-profil"
                ref={passwordRef}
                onBlur={handleSave}
              >
                {newpassword}
              </div>
              <div className="title-info-profil">
                Confirmation du mot de passe :
              </div>
              <div
                contenteditable="true"
                className="info-profil"
                ref={passwordconfirmRef}
                onBlur={handleSave}
              >
                {newpasswordconfirm}
              </div>
              <div className="botom-profil-edit">
                <div
                  className="edition-button-container"
                  onClick={geteditProfile}
                >
                  Annuler
                </div>
                <div
                  className="edition-button-container"
                  onClick={updateprofil}
                >
                  Sauvegarder
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="left-profil-container">
            <img
              src="chemin-vers-votre-image.jpg"
              alt="profil-pic"
              className="profile-pic"
            />
            <div className="locaux-contrats-container">
              <div className="locaux-contrats-text">{numberLocaux} locaux</div>
              <img
                src="house.svg"
                alt="house-pic"
                className="locaux-contrats-pic"
              />
            </div>
            <div className="locaux-contrats-container">
              <div className="locaux-contrats-text">{numberContract}  Contrats</div>
              <img
                src="file.png"
                alt="house-pic"
                className="locaux-contrats-pic"
              />
            </div>
            <div className="locaux-contrats-container">
              <div className="locaux-contrats-text">{numberbills} factures</div>
              <img
                src="file.png"
                alt="file-pic"
                className="locaux-contrats-pic"
              />
            </div>
            <div className="edit-profil-container" onClick={geteditProfile}>
              Editer
            </div>
            <div className="delete-profil-container" onClick={deleteAccount}>
              Supprimer votre compte
            </div>
          </div>
          <div className="vertical-line"></div>
          <div className="right-profil-container">
            <div className="info-profil-container">
              <div className="title-info-profil">Prénom :</div>
              <div className="info-profil">{firstname}</div>
              <div className="title-info-profil">Nom :</div>{" "}
              <div className="info-profil">{lastname}</div>
              <div className="title-info-profil">Tel :</div>
              <div className="info-profil">{phone}</div>
              <div className="title-info-profil">Mail :</div>
              <div className="info-profil">{email}</div>
              <div className="title-info-profil">Mot de passe :</div>
              <div className="info-profil">********</div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="profilPage">
      <div className="profilContainer">{getProfilPage()}</div>
    </div>
  );
}

export default Profile;
