import React, { useEffect, useState } from 'react';
import './LocauxDisplayAdd.css';
import { useNavigate } from 'react-router-dom';

const LocauxDisplayAdd = ({ SelectLocal, CreateLocal, Locaux, localId, localRequests, SelectRequest, reqView }) => {
  const [selectedLocal, setSelectedLocal] = useState(null);
  const navigate = useNavigate();

  const ChooseLocal = (local) => {
    setSelectedLocal(local);
    SelectLocal(local);
    navigate({ ...navigate.location, state: null }, { replace: true });
  }
  const chooseRequest = () => {
    setSelectedLocal(null);
    SelectLocal(null);
    SelectRequest();
  }
  const HandleCreateLocal = () => {
    setSelectedLocal({});
    SelectLocal({});
    CreateLocal();
  }
  useEffect(() => {
    if (localId) {
      setSelectedLocal(Locaux.find((local) => local.id === localId));
    } else if (localStorage.getItem("needTutorial") === "true") {
      setSelectedLocal(Locaux[0]);
      ChooseLocal(Locaux[0]);
    }
  }, [Locaux, localId]);

  const returnLocalList = () => {
    return (
      <>
        {Locaux.map((local) => {
          return (
            <div key={local.id}>
              <div
                className={`local-container ${selectedLocal === local ? 'selected-local' : ''}`}
                onClick={() => ChooseLocal(local)}
              >
                {!local.photo && (<div id='black-pic-local-pres'></div>)}
                {local.photo && (<img src={local.photo.url} id='local-pic-app' />)}
                <div className='local-name'>{local.domicileName}</div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
  const getStyleBg = () => {
    if (localRequests?.length > 0) {
      localRequests.forEach(element => {
        if (element.photo) {
          return { backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url(${element.photo.url})` };
        }
      });
    } 
    return { backgroundColor: 'black' };
  }

  return (
    <div className='container-global-domicile-carroussel'>
      <div className='container-domicile-carroussel'>
        {Locaux.length === 0 && (
          <div className='no-local-txt'>Vous n'avez pas encore de local</div>
        )}
        {localRequests?.length > 0 && (
          <>
            <div className={`local-request-container ${reqView ? 'selected-local' : ''}`}
            style={getStyleBg()}
            onClick={chooseRequest}>
              <div id='nb-local-requests'>+{localRequests.length}</div>
              <div id='local-request-text'><p>Nouvelles demandes</p></div>
            </div>
            <img src="separator-local.svg" alt="" id='separator-local' />
          </>
        )}
        {Locaux.length > 0 && returnLocalList()}
        <div>
          <div className='add-local-button' onClick={HandleCreateLocal}>
            <img src="purple-plus.svg" alt="" />
            <div className='add-local-txt'>Ajouter un local</div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default LocauxDisplayAdd;