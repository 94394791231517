import React from 'react';
import './BentoLandingPage.css';
const BentoLandingPage = () => {
  return (
    <div id="wrapper-bento-landing">
        <div className="box-bento-landing a">
          <div id="bento-landing-a-elem-content">
            <div className="bento-landing-title">Coverage, Assurez vous d'être couvert</div>
            <div id="bento-landing-a-subtitle">Qu'est ce que Coverage ?</div>
            <div className="bento-landing-text">
              Coverage est une application mobile IOS, Android et un site web d'analyse automatique de contrat d'assurance qui apportera une analyse détaillée de vos contrats d'assurance. Coverage vous permet ainsi d'optimiser vos assurances et de connaître votre couverture totale, vous faisant économiser du temps et de l’argent ! Stockez en ligne de manière sécurisée toutes vos factures pour les classer et obtenir une vue d’ensemble de vos biens. Télécharger à tout moment vos factures et contrats d’assurance en toute simplicité en cas de sinistre. Réaliser des simulations de sinistre avec vos contrats d'assurance pour être couvert en toute circonstance ! 
            </div>
          </div>
          <img id="coverage-team" src="coverage-team.svg" alt="coverage-team"/>
        </div>
        <div className="box-bento-landing b">
        <div className="bento-landing-title-sub">Une app disponible sur tous les supports</div>
          <div className="bento-landing-text">Accédez à toutes les fonctionnalités de Coverage n'importe où, à porté de main ! 🤚</div>
          <div id='container-mockup-coverage'>
            <img src="mockup-coverage.png" alt="mockup-coverage"/>
          </div>
        </div>
        <div className="box-bento-landing c">
          <div className="bento-landing-title-sub">Analyse intelligente de factures et contrats d'assurances 🔎</div>
          <div className="bento-landing-text bento-landing-paragraph">
            <div className="">
              Suivez la valeur de votre patrimoine en 
              temps réel en ajoutant vos factures à vos 
              domiciles. Notre IA spécialisée se chargera 
              de déduire le montant et le thème de votre 
              facture pour les classer par catégories.</div>
          </div>

          <div className="bento-landing-text bento-landing-paragraph">
            <div>Connectez vous à votre assureur dans
              l'application Coverage et obtenez rapidement
              une synthèse de tous vos contrats d'assurance. 
              Ainsi suivez en temps réel la couverture 
              de votre patrimoine en cas de sinistre. </div>
            </div>
        <div className="">
          <div className="bento-landing-text bento-landing-paragraph">
            Recevez des notifications vous rappelant de stocker vos factures pour 
            les restituer à votre assureur en cas de sinistre. Et renouveler vos contrats lorsqu'ils 
            arrivent en fin d'échéance.
          </div>
        </div>
        </div>
        <div className="box-bento-landing d">
          <div className="bento-landing-title-sub">Stocker en toute sécurité 🔐</div>
          <div className="bento-landing-text">Ajoutez simplement toutes vos factures et vos contrats
            d'assurances pour y accéder à tout moment.
            Choisissez de les ajouter en ligne pour plus de sécurité
            et les partager avec les membre gestionnaires 
            de vos “Domiciles”.
          </div>
        </div>
        <div className="box-bento-landing e">
          <div className="bento-landing-title-sub">Simulation de sinistre 🔥</div>
          <div className="bento-landing-text">Grâce aux contrats et factures importés dans l'application,
            réalisez simplement des simulations de sinistre sur un de vos “Domiciles”. Testez ainsi les limites de votre contrat
            d'assurance ou vérifiez si un cas précis est bien couvert
            à l'aide de notre IA d'analyse spécialisée.
            </div>
        </div>
      </div>
  );
};

export default BentoLandingPage;
