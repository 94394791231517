import React, { useState } from "react";
import "./ForgetPassword.css";


const ForgetPassword = () => {
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [password, setPassword] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const changePasswordConfirm = (event) => {
    setPasswordConfirm(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const verifpassword = async () => {
    if (password !== passwordConfirm) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }
    if (
      !password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$/g
      )
    ) {
      alert(
        "Le mot de passe doit contenir une majuscule, une minuscule, un chiffre, un caractère spécial et 8 caractères au minimum."
      );
      return;
    }
    let result = await fetch(
      "https://api.coverage-eip.com/account/",
      {
        method: "put",
        body: JSON.stringify({
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (result.status == 200) {
      window.location.reload();
      window.location.href = "/";
      alert("Votre mot de passe à bien été modifié");
    } else {
      alert(result.msg);
      window.location.href = "/";
    }
  };

  return (
    <div id="forgetPage">
      <div id="forgetContainer">
        <div id="form-forget-container">
          <div id="title-forget-container">Modifier votre mot de passe</div>
          <div id="subtitle-forget-container">Saisissez votre nouveau mot de passe pour accéder à votre compte Coverage</div>
          <input type="password" placeholder="Mot de passe" className="input-forget-password" value={password} onChange={changePassword}/>
          <input type="password" placeholder="Confirmer le mot de passe" className="input-forget-password" value={passwordConfirm} onChange={changePasswordConfirm}/>
          <button id="connect-button-forget" onClick={verifpassword}>Changer mon mot de passe</button>
          <div className="separator">
            <div className="line"></div>
            <span className="separator-text">OU</span>
            <div className="line"></div>
          </div>
          <a href="/Register" id="register-redirect-forget">Créer un compte</a>
        </div>
        <div id="forget-img-container">
          <img src="mockup-login.jpg" alt="mockup-forget-pic" id="mockup-forget" />
        </div>
      </div>  
    </div>
  );
};

export default ForgetPassword;
