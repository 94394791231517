import React from 'react';
import './CardNotif.css';

const CardNotif = ({type, name}) => {
    const handleRedirect = () => {
        if (type === 'contact') {
            window.location.href = '/Collaborators';
        }
        if (type === 'local') {
            window.location.href = '/Home';
        }
    };

    return (
        <div className='card-notif-dashboard-container' onClick={handleRedirect}>
            <div className='container-pic-notif-dashboard'>
                {type === 'contact' && <img src="peoples.svg" alt="people-icon" />}
                {type === 'local' && <img src="building.svg" alt="building-icon" />}
            </div>
            <div className='container-txt-notif-dashboard'>
                {type === 'contact' && <div>Demande de collaboration</div>}
                {type === 'local' && <div>Invitation local</div>}
                <span>{name} vous à envoyé une invitation</span>
            </div>
        </div>
    );
};

export default CardNotif;