import React from 'react';
import './BillsDisplay.css';
import { useState, useEffect, useRef } from 'react';

const BillsDisplay = ({ bill, closeBill }) => {
    const [initialBill] = useState(JSON.parse(JSON.stringify(bill)));
    const [billState, setBill] = useState({...bill });
    const [edit, setEdit] = useState(false);
    const [newProductName, setNewProductName] = useState('');
    const [newProductPrice, setNewProductPrice] = useState('');
    const [newProductQte, setNewProductQte] = useState('');
    const [suggestedTotalPrice, setSuggestedTotalPrice] = useState(bill.totalAfterTax);

    const handleTotalPriceChange = (e) => {
        setSuggestedTotalPrice(parseFloat(e.target.value));
    };
    const calculateSuggestedTotalPrice = () => {
        let total = 0;
        billState.products.forEach(product => {
            total += product.priceAfterTax * product.quantity;
        });
        setSuggestedTotalPrice(total);
    };
    const handleProductNameChange = (e, index) => {
        const updatedProducts = [...billState.products];
        updatedProducts[index].name = e.target.value;
        setBill({ ...billState, products: updatedProducts });
    };
    const handleProductPriceChange = (e, index) => {
        const updatedProducts = [...billState.products];
        updatedProducts[index].priceAfterTax = parseFloat(e.target.value);
        setBill({ ...billState, products: updatedProducts });
        calculateSuggestedTotalPrice();
    };
    const handleProductQuantityChange = (e, index) => {
        const updatedProducts = [...billState.products];
        updatedProducts[index].quantity = parseFloat(e.target.value);
        setBill({ ...billState, products: updatedProducts });
        calculateSuggestedTotalPrice();
    };
    const convertDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}/${month}/${year}`;
    };

    const handleDeleteProduct = (index) => {
        setBill((prevBill) => {
            const updatedProducts = [...prevBill.products];
            updatedProducts.splice(index, 1);
            return { ...prevBill, products: updatedProducts };
        });
        calculateSuggestedTotalPrice();
    };

    const handleAddProduct = () => {
        if (newProductName.trim() === '' || newProductPrice.trim() === '') {
            alert('Veuillez remplir tous les champs.');
            return;
        }
        const newProduct = {
            name: newProductName,
            priceAfterTax: parseFloat(newProductPrice),
            quantity: parseFloat(newProductQte),
            currency: billState.currency,
        };
        let tampPrice = parseFloat(newProductPrice) * parseFloat(newProductQte);
        setSuggestedTotalPrice(suggestedTotalPrice + tampPrice);
        setBill((prevBill) => ({
            ...prevBill,
            products: [...prevBill.products, newProduct],
        }));
        setNewProductName('');
        setNewProductQte('');
        setNewProductPrice('');
    };
    const cancelButton = () => {
        console.log('cancel');
        console.log(bill)
        setEdit(false);
        setBill({ ...initialBill });
        setSuggestedTotalPrice(bill.totalAfterTax);
    };
    const handleApplyChanges = async () => {
        let result = await fetch(`https://api.coverage-eip.com/bills/${billState.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                domicileId: bill.domicileId,
                billNumber: bill.billNumber,
                billDate: bill.billDate,
                totalAfterTax: suggestedTotalPrice,
                currency: bill.currency,
                products: billState.products,
                tags: bill.tags,
                storeName: bill.storeName,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (result.status === 200) {
            result = result.json().then((data) => {
                setEdit(false);
                setBill(data);
            });
        } else {
            alert('Echec de la modification de la facture');
        }       
    };
    return (
        <div className='container-display-bill-infos'>
            <div className='display-bill-infos'>
                <div className='title-section-bills-details'>
                    <div className='bills-name-detail'>Facture - {billState.storeName}</div>
                    {!edit && (
                        <div className='edit-button-bills-details' onClick={() => setEdit(true)}><img src="pen-edit.svg" alt="" />Editer</div>
                    )}
                </div>
                <div id='buy-date-bill-display'>Date d'achat: {convertDate(billState.billDate)}</div>
                <div id='container-product-list-bill-display'>
                    <span id='title-list-product-bill-display'>Liste des articles: </span>
                    {billState.products.map((product, index) => (
                        <div key={index} className='line-product-bill-display'>
                            <input
                                type='text'
                                value={product.name}
                                onChange={(e) => handleProductNameChange(e, index)}
                                readOnly={!edit}
                                className='input-edit-infos-bill input-edit-infos-bill-name'
                            />
                            <input
                                type='number'
                                value={product.priceAfterTax}
                                onChange={(e) => handleProductPriceChange(e, index)}
                                readOnly={!edit}
                                className='input-edit-infos-bill input-edit-infos-bill-price'
                            />
                            <input
                                type='number'
                                value={product.quantity}
                                onChange={(e) => handleProductQuantityChange(e, index)}
                                readOnly={!edit}
                                className='input-edit-infos-bill input-edit-infos-bill-qte'
                            />
                            {edit && (
                                <img src='trash.svg' onClick={() => handleDeleteProduct(index)}></img>
                            )}
                        </div>
                    ))}
                    {edit && (
                        <div id='container-add-product'>
                            <input
                                type='text'
                                placeholder='Nom du produit'
                                value={newProductName}
                                onChange={(e) => setNewProductName(e.target.value)}
                                className='input-edit-infos-bill input-edit-infos-bill-name'
                            />
                            <input
                                type='number'
                                placeholder='Prix du produit'
                                value={newProductPrice}
                                onChange={(e) => setNewProductPrice(e.target.value)}
                                className='input-edit-infos-bill input-edit-infos-bill-price'
                            />
                            <input 
                                type='number' 
                                placeholder='Quantité' 
                                value={newProductQte} 
                                onChange={(e) => setNewProductQte(e.target.value)}
                                className='input-edit-infos-bill input-edit-infos-bill-qte'
                            />
                            <span onClick={handleAddProduct} id='add-product-button-display-bill'>+</span>
                        </div>
                    )}
                </div>
                <div id='total-bill'>Total: <input type='number' className='input-edit-infos-bill input-edit-infos-bill-totalprice' value={suggestedTotalPrice} onChange={handleTotalPriceChange} readOnly={!edit}/>{billState.currency}</div>
                <div className='buttons-display-bill-container'>
                    {edit && (
                        <>
                            <div className='button-cancel-bills' onClick={cancelButton}>
                                Annuler
                            </div>
                            <div className='button-apply-bills' onClick={handleApplyChanges}>Appliquer les modifications</div>
                        </>
                    )}
                    {!edit && (
                        <>
                            <div className='button-cancel-bills' onClick={closeBill}>
                                Fermer
                            </div>
                            <div className='button-apply-bills' onClick={() => {window.open(billState.images[0].url, '_blank');}}>
                                Télécharger la facture <img src="download.svg" alt="download svg" id='download-bill-display' />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BillsDisplay;