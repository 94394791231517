import React, { useEffect, useState } from "react";
import "./BillsImportAndDisplay.css";

const BillsImportAndDisplay = ({ seeBill, domicileId, local }) => {
  const [dragging, setDragging] = useState(false);
  const [fileToConvert, setFileToConvert] = useState("");
  const [bills, setBills] = useState([]);
  var billToAnalyze = "";
  // console.log(local);
  
  const fetchData = async () => {
    let result = await fetch("https://api.coverage-eip.com/bills", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    result = await result.json();
    console.log(result);
    let tamp = result.filter((bill) => bill.domicileId === domicileId);
    setBills(tamp);
    // console.log(local);
  };
  useEffect(() => {
    fetchData();
  }, [domicileId]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileSelect = (e) => {
    e.preventDefault();
    const files = [...e.target.files];
    handleDrop(files);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const droppedFiles = [...e.dataTransfer.files];

    const acceptedTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!acceptedTypes.includes(droppedFiles[0].type)) {
      alert("Veuillez sélectionner un fichier PDF, JPG ou PNG.");
      return;
    }

    setFileToConvert(droppedFiles);
    const base64 = await convertToBase64(droppedFiles[0]);
    if (base64) {
      billToAnalyze = base64;
      analyzeBill();
    }
  };

  const analyzeBill = async () => {
    // console.log(billToAnalyze);
    let result = await fetch("https://api.coverage-eip.com/bills/analyze", {
      method: "post",
      body: JSON.stringify({
        data: billToAnalyze,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      result = result.json().then((data) => {
        // console.log(data);
        addBill(data);
      });

      // window.location.reload();
    } else {
      alert("Echec de l'importation de la facture");
    }
  };

  const addBill = async (data) => {
    // console.log(billToAnalyze);
    let result = await fetch("https://api.coverage-eip.com/bills/", {
      method: "post",
      body: JSON.stringify({
        domicileId: domicileId,
        billNumber: data.billNumber,
        billDate: data.billDate,
        totalAfterTax: data.totalAfterTax,
        currency: data.currency,
        products: data.products.map((product) => ({
          name: product.name,
          quantity: product.quantity,
          priceAfterTax: product.priceAfterTax,
          currency: product.currency,
        })),
        data: billToAnalyze,
        tags: data.tags,
        storeName: data.storeName,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      result = result.json().then((data) => {
        // console.log(data);
        window.location.reload();
      });
    } else {
      alert("Echec de l'importation de la facture");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDeleteBill = async (billId) => {
    try {
      const response = await fetch(
        `https://api.coverage-eip.com/bills/${billId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.ok) {
        alert("Facture supprimée avec succès.");
        window.location.reload();
      } else {
        alert(`Erreur lors de la suppression: ${response.status}`);
      }
    } catch (error) {
      alert(`Erreur lors de la suppression: ${error.message}`);
    }
  };

  return (
    <div className="container-global-bill-import">
      <div className="container-bill-import">
        <div
          className={`upload-bills-container ${dragging ? "dragging" : ""}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {/* <input
    type="file"
    multiple
    accept=".pdf,.jpg,.png"
    onChange={handleFileSelect}
  /> */}
          <div>Glissez une facture pour l'ajouter</div>
        </div>
        <div id="bills-display-container">
          {bills.map((bill, index) => (
            <div className="bills-elem margin-bills-elem" key={index}>
              <div className="import-bills-container">
                <img src="receip.svg" className="bill-illustration" />
                <div className="bills-price-elem">
                  {" "}
                  {bill.totalAfterTax} {bill.currency}
                </div>
              </div>
              <div className="container-expiration-name">
                <div className="name-bill-import">{bill.storeName}</div>
                <div className="buy-bill-import">
                  <span>Achat :</span> Date:{" "}
                  {new Date(bill.billDate).toLocaleDateString("fr-FR")}
                </div>
                <div className="types-bill-import">
                  <span>Types :</span> {bill.tags.join(", ")}
                </div>
                <div className="buttons-bill-elem-container">
                  <div className="button-update-bill"
                  onClick={() => seeBill(bill)}>
                    Consulter
                    <img src="right-arrow-purple.svg" />
                  </div>
                  <div
                    className="button-delete-bill"
                    onClick={() => handleDeleteBill(bill.id)}
                  >
                    Supprimer
                    <img src="red-bin.svg" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillsImportAndDisplay;
