import React, { useState, useEffect } from "react";
import "../header/header.css";
import CheckAuth from "../../Auth";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 600px)").matches);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 600px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const disconnect = async () => {
    localStorage.clear();
    window.location = "/";
  };

  return (
    <div className={scroll ? "sticky-menu background_menu" : "sticky-menu"}>
      <div id="header">
        <div id="menu">
          {isMobile ? (
            <a id="coverageHome-logo-header" href="/">
              <img src="logo_Coverage.png" alt="" />
            </a>
            ) : (
              <a id="coverageHome" href="/">
                Coverage
              </a>
            )}
          {CheckAuth() ? (
              <>
                <div id="header-right-buttons">
                  <div id="my-space-header" onClick={() => window.location="/Dashboard"}>Mon Espace</div>
                  {isMobile ? (
                    <div id="disconnect-button-header" onClick={disconnect}><img src="logout-white.svg" id="img-logout"/></div>
                  ) : (
                    <div id="disconnect-button-header" onClick={disconnect}>Déconnexion<img src="logout-white.svg" id="img-logout"/></div>
                  )}
                </div>
              </>
          ) : (
            <button id="connect-button-header" onClick={() => window.location="/Login"}>Connexion</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
