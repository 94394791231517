import React, {useState, useEffect} from 'react';
import './LocalPopupContent.css';

const LocalPopupContent = ({state, onClose, editable, initialValues = null}) => {
    const [collabs, setCollabs] = useState([]);
    const [localValues, setLocalValues] = useState(initialValues);
    const disable = !localValues.domicileName || !localValues.number || !localValues.street || !localValues.city || !localValues.postalCode || !localValues.country;
    const handleNameChange = (event) => {
        setLocalValues((prevValues) => ({
            ...prevValues,
            domicileName: event.target.value,
        }));
    };
    const getListContacts = async () => {
        let result = await fetch("https://api.coverage-eip.com/contacts", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (result.status === 200) {
            result = result.json().then((data) => {
                setCollabs(data);
            });
        } else {
        }
    };
    useEffect (() => {
        setLocalValues(initialValues);
        getListContacts();
    }, [initialValues]);
    
    useEffect(() => {
        if (!state) {
            setLocalValues([]);
        }
    }, [state]);

    const CreateLocal = async () => {
        let picData = null;
        if (localValues.photo) {
            picData = localValues.photo.split(',')[1];
        }
        let result = await fetch("https://api.coverage-eip.com/domicile", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                domicileName: localValues.domicileName,
                country: localValues.country,
                city: localValues.city,
                postalCode: localValues.postalCode,
                street: localValues.street,
                number: localValues.number,
                photo: picData,
            }),
        });
        // console.log(result);
        //marche pas
        // for (let i = 0; i < collabs.length; i++) {
        //     if (document.getElementById(`item${i}`).checked) {
        //         console.log(collabs[i]);
        //         sendDomicileRequest(result.id, collabs[i].id);
        //     }
        // }
        setLocalValues([]);
        onClose();
    }
    const EditLocal = async () => {
        let picData = localValues.photo;
        // console.log(localValues.photo);
        if (localValues.photo?.url.includes('data:image')) {
            picData = localValues.photo.split(',')[1];
        }
        let result = await fetch("https://api.coverage-eip.com/domicile/" + localValues.id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                domicileName: localValues.domicileName,
                country: localValues.country,
                city: localValues.city,
                postalCode: localValues.postalCode,
                street: localValues.street,
                number: localValues.number,
                photo: picData,
              }),
        });
        for (let i = 0; i < collabs.length; i++) {
            if (document.getElementById(`item${i}`).checked) {
                sendDomicileRequest(localValues.id, collabs[i].id);
            }
        }
        setLocalValues([]);
        onClose();
    }
    const sendDomicileRequest = async (domicileId, contactId) => {
        let result = await fetch("https://api.coverage-eip.com/domicileRequest", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                domicileId: domicileId,
                contactId: contactId,
            }),
        });
        if (result.status === 200) {
            result = result.json().then((data) => {
                alert(data.msg);
            });
        } else {
            alert("Code d'arreur : " + result.status);
        }
    };
    const handleNumberChange = (event) => {
        setLocalValues((prevValues) => ({
            ...prevValues,
            number: event.target.value,
        }));
    };
    
    const handleStreetChange = (event) => {
        setLocalValues((prevValues) => ({
            ...prevValues,
            street: event.target.value,
        }));
    };

    const handleCityChange = (event) => {
        setLocalValues((prevValues) => ({
            ...prevValues,
            city: event.target.value,
        }));
    };
    const handlePostalChange = (event) => {
        setLocalValues((prevValues) => ({
            ...prevValues,
            postalCode: event.target.value,
        }));
    };
    const handleCountryChange = (event) => {
        setLocalValues((prevValues) => ({
            ...prevValues,
            country: event.target.value,
        }));
    };
    useEffect(() => {
        if (editable) {
            setLocalValues(initialValues);
        }
    }, [editable]);

    const handleClose = () => {
        if (onClose) {
            setLocalValues([]);
            onClose();
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = e.target.result;
          setLocalValues((prevValues) => ({
            ...prevValues,
            photo: base64String,
          }));
        };
        reader.readAsDataURL(file);
      };
    const handleClickPicture = () => {
        document.getElementById('fileInput').click();
    };
    return (
        <>
            <img className="cross-popup" src="cross.svg" alt="Icon cross" onClick={handleClose}/>
            {editable && (<div id='title-popup'>Modifier un local</div>)}
            {!editable && (<div id='title-popup'>Créer un local</div>)}
            <div id='container-photos-collabos-popup'>
                <div id='picture-local-popup'>
                    {editable && (
                        <div id="pic-local-popup">Photos</div>
                    )}
                    <div id='container-pic-popuplocal'>
                        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} accept="image/jpeg, image/png"/>
                        <div id='localpopup-container-elem' onClick={handleClickPicture}>
                            {!localValues.photo && (<div id='black-pic'></div>)}
                            {localValues.photo && (<img src={localValues.photo.url} id='localpopup-pic-app'/>)}
                            <img src="appareil-photos.svg" id='logo-appareil-elem-localpopup' alt="" />
                            {editable && (<div id='edit-text-pic-localpopup'>Modifier</div>)}
                            {!editable && (<div id='edit-text-pic-localpopup'>Ajouter</div>)}
                        </div>
                    </div>
                </div>
                {editable && (
                    <div id='collabos-popup-local'>
                    <div id='collaborators-title-popup'>Collaborateurs</div>
                    <div id='container-list-popup-local'>
                        {collabs.length === 0 && (
                            <label>Aucun collaborateurs</label>
                        )}
                        <ul id="ul-localpopup-collabs">
                            {collabs.map((collaborator, index) => (
                                <li key={index} className='li-local-popup-collaborators'>
                                    <input type="checkbox" id={`item${index}`} name={`item${index}`} />
                                    <label className='span-list-collabs-popuplocal' htmlFor={`item${index}`}>{collaborator.firstName +" " + collaborator.lastName}</label>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                )}
            </div>
            <div>Nom</div>
            <div>
                <input type="text" id="name-local-popup" placeholder='Nom du local' value={localValues.domicileName} onChange={handleNameChange} />
            </div>
            <div>Adresse</div>
            <div>
                <input type="text" id="number-local-popup" placeholder='Numéros' value={localValues.number} onChange={handleNumberChange}/>
                <input type="text" id="street-local-popup" placeholder='Rue' value={localValues.street} onChange={handleStreetChange}/>
                <input type="text" id="city-local-popup" placeholder='Ville' value={localValues.city} onChange={handleCityChange} />
                <input type="text" id="postal-local-popup" placeholder='Code postal' value={localValues.postalCode} onChange={handlePostalChange} />
                <input type="text" id="country-local-popup" placeholder='Pays' value={localValues.Country} onChange={handleCountryChange} />
            </div>
            {editable && (<button id="button-create-local-popup" className={disable ? 'disable-button' : ''} onClick={EditLocal} disabled={disable}>Editer</button>)}
            {!editable && (<button id="button-create-local-popup" className={disable ? 'disable-button' : ''} onClick={CreateLocal} disabled={disable}>Créer</button>)}
        </>
    );
};

export default LocalPopupContent;