import React, { useState } from 'react';
import { Button, Modal } from '@mui/material';

const UniversalModal = ({ isOpen, onClose, children }) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            boxShadow: 24,
            padding: 4,
            outline: 'none',
            borderRadius: 8,
            padding: 20,
          }}
        >
          {children}
        </div>
      </Modal>
    </>
  );
};

export default UniversalModal;