import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import "./BgAnimated.css";
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import Register from "./container/subscribe/register.jsx";
import Welcome from "./container/welcome/welcome.jsx";
import Header from "./container/header/header";
import Footer from "./container/footer/footer.jsx";
import CheckAuth from "./Auth";
import Login from "./container/login/login.jsx";
import Bills from "./container/Bills/Bills.jsx";
import Home from "./component/Home/Home.jsx";
import Dashboard from "./container/Dashboard/Dashboard.jsx";
import Contract from "./container/Contract/Contract.jsx";
import Collaborators from "./component/Collaborators/Collaborators.jsx";
import Profile from "./container/Profile/Profile.jsx";
import Popup from "./component/Popup/Popup";
import ForgetPassword from "./container/ForgetPassword/ForgetPassword";
import './variables.css';
import SideMenu from "./container/SideMenu/SideMenu.jsx";
import GetTutoSteps from "./GetTutoSteps";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [joyrideState, setJoyrideState] = useState(GetTutoSteps());
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [theme, setTheme] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const ref = useRef(null);
  const openModalForTuto = useRef(null);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme === "true");
    }
  }, []);

  useEffect(() => {
    const savedState = localStorage.getItem("isSwitchOn");
    if (savedState) {
      setIsSwitchOn(savedState === "true");
    }
  }, []);

  const handleState = (message) => {
    setMessage(message);
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const handleCallback = (data) => {
    const { action, index, type, status } = data;
    const { next, previous } = joyrideState.steps[index].data;

    if (type === 'step:after' && (next || previous)) {
      const nextRoute = action === 'prev' ? previous : next;
      navigate(nextRoute);

      if (type === 'step:after' && index === 1) {
        if (openModalForTuto.current) {
          openModalForTuto.current();
          pauseJoyride();
          return;
        }
      }
      setTimeout(() => {
        setJoyrideState((prevState) => ({
          ...prevState,
          run: true,
          stepIndex: index + (action === 'prev' ? -1 : 1),
        }));
      }, 500);
    }

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setJoyrideState((prevState) => ({
        ...prevState,
        run: false,
        stepIndex: 0,
      }));
      localStorage.setItem("needTutorial", false);
      console.log("Tutorial completed", localStorage.getItem("needTutorial"));
    }
    
  };
  const pauseJoyride = () => {
    setTimeout(() => {
    setJoyrideState((prevState) => ({
      ...prevState,
      run: false,
      stepIndex: joyrideState.stepIndex + 1,
    }));
    }, 500);
  };

  const resumeJoyride = () => {
    setTimeout(() => {
      setJoyrideState((prevState) => ({
        ...prevState,
        run: true,
      }));
    }, 500);
  };

  return (
    <div className="parent-container">
      <div className="absolute-bg">
        <div className="absolute-bg inset-0 justify-center"></div>
        <div className="bg-shape1 bg-teal opacity-50 bg-blur"></div>
        <div className="bg-shape2 bg-primary opacity-50 bg-blur"></div>
        <div className="bg-shape1 bg-purple opacity-50 bg-blur"></div>
      </div>
      <div className={`${theme}`}>
        <Header />
        {CheckAuth() ? (
          <div className="main-container">
            <div className={`side ${location.pathname === '/' ? 'hide' : ''}`}>
              <SideMenu />
            </div>
            <div className={`main-content${location.pathname === '/' ? '-landing' : ''}`} ref={ref}>
              {
              localStorage.getItem("needTutorial") === "true" && 
              joyrideState ? (
                <Joyride
                  callback={handleCallback}
                  continuous
                  run={joyrideState.run}
                  stepIndex={joyrideState.stepIndex}
                  steps={joyrideState.steps}
                  styles={{
                    options: {
                      zIndex: 10000,
                      arrowColor: '#fff',
                      backgroundColor: '#fff',
                      overlayColor: 'rgba(0, 0, 0, 0.5)',
                      primaryColor: '#000',
                      textColor: '#000',
                    },
                  }}
              />
              ) : null}
              
              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/Register" element={CheckAuth() ? <Navigate to="/" /> : <Register />} />
                <Route path="/Login" element={CheckAuth() ? <Navigate to="/" /> : <Login />} />
                <Route path="/Collaborators" element={<Collaborators />} />
                <Route path="/Bills" element={<Bills />} />
                <Route path="/Profile" element={<Profile />} />
                <Route path="/Home" element={<Home openModalForTuto={openModalForTuto} pauseJoyride={pauseJoyride} resumeJoyride={resumeJoyride}/>} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/Contract" element={<Contract />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              {open ? (
                <Popup text={message} closePopup={() => setOpen(false)} />
              ) : null}
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/Register" element={CheckAuth() ? <Navigate to="/" /> : <Register />} />
            <Route path="/Login" element={CheckAuth() ? <Navigate to="/" /> : <Login />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </div>
      <Footer />
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;