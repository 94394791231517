import React, {useEffect, useState} from 'react';
import './Collaborators.css';

const Collaborators = () => {
    const [mail, setEmail] = useState("");
    const [collaborators, setCollaborators] = useState([]);
    const [collaboratorsRequest, setCollaboratorsRequest] = useState([]);
    let regMail = /^[\w\-\.]+@([\w\-]+\.)+[\w\-]{2,4}$/g;

    const changeEmail = (event) => {
        setEmail(event.target.value);
    };
    const getListContacts = async () => {
        let result = await fetch("https://api.coverage-eip.com/contacts", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (result.status === 200) {
            result = result.json().then((data) => {
                setCollaborators(data);
            });
        }
    };
    const getListContactsRequest = async () => {
        let result = await fetch("https://api.coverage-eip.com/contactRequest/contact", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (result.status === 200) {
            result = result.json().then((data) => {
                setCollaboratorsRequest(data);
            });
        }
    };
    useEffect(() => {
        getListContacts();
        getListContactsRequest();
    }, []);

    const addCollaborator = async () => {
        let type = "email";
        if (mail.match(/^\d{10}$/)) {
            type = "phoneNumber";
        } else if (!mail.match(regMail)) {
            alert("Mauvais format d'adresse email");
            return;
        }
        addCollaboratorRequest(type);
    }
    const refuseCollaborator = async (requestId) => {
        let result = await fetch("https://api.coverage-eip.com/contactRequest/decline/" + requestId, {
            method: "post",
            body: JSON.stringify({
                email: mail,
            }),
            headers: {
                "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        
        if (result.status === 200) {
            result = result.json().then((data) => {
                alert(data.msg);
            });
        } else {
            alert(result.statusText);
        }
        alert("La demande de collaboration à été refusée");
        setEmail("");
    };
    const acceptCollaborator = async (requestId) => {
        let result = await fetch("https://api.coverage-eip.com/contactRequest/accept/" + requestId, {
            method: "post",
            body: JSON.stringify({
                email: mail,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        
        if (result.status === 200) {
            result = result.json().then((data) => {
                alert(data.msg);
            });
        } else {
            alert(result.statusText);
        }
        alert("La demande de collaboration à été acceptée")
        setEmail("");
    };
    const addCollaboratorRequest = async (type) => {
        let result = await fetch("https://api.coverage-eip.com/contactRequest", {
            method: "post",
            body: JSON.stringify({
                method: type,
                data: mail,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        if (result.status === 200) {
            result = result.json().then((data) => {
                alert(data.msg);
            });
        } else {
            alert(result.statusText);
        }
        alert("Un mail de demande de collaboration à été envoyé à l'adresse mail " + mail + " renseignée")
        setEmail("");
    };
    const deleteCollaborator = async (contactId) => {
        let result = await fetch("https://api.coverage-eip.com/account/contact", {
            method: "DELETE",
            body: JSON.stringify({
                contactId : contactId,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        if (result.status === 200) {
            result = result.json().then((data) => {
                alert(data.msg);
            });
        } else {
            alert(result.statusText);
        }
    };
    return (
        <div id='bento-container-collabs-page'>
            <div id="left-elem-collabs-page-container">
                <div id="left-elem-collabs-page">
                    <div className="title-bento-part-collabs">Collaborateurs</div>
                    <div className='contact-list-container'>
                        <div className="contact-list">
                            {collaborators.map((collaborator) => {
                                return (
                                    <div className='contact-list-elem'>
                                        <div className='id-contact-container'>
                                            <div className='name-collaborator-elem'>{collaborator.firstName + " " + collaborator.lastName}</div>
                                            <div className='email-collaborator-elem'>{collaborator.email}</div>
                                        </div>
                                        <div className='delete-contact-collaborators' onClick={() => deleteCollaborator(collaborator.id)}><img src='trash.svg'/></div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div id='right-elem-collabs-page'>
                <div id="top-right-elem-collabs-page">
                <div className="title-bento-part-collabs">Ajouter un collaborateur</div>
                     <div id='container-input-button-add-collab'>
                         <input type="text" placeholder="Addresse mail" className="input-add-collaborators" value={mail} onChange={changeEmail}/>
                         <button className="button-add-collaborators" onClick={addCollaborator}>Ajouter</button>
                     </div>
                </div>
                <div id='bottom-right-elem-collabs-page'>
                    <div className="title-bento-part-collabs">
                        Demande de collaborateurs
                    </div>
                    <div className='contact-list-container-request'>
                        {collaboratorsRequest.map((request) => {
                            return (
                                <div className='contact-list-elem-request'>
                                    <div className='id-contact-container-request'>
                                        <div className='name-collaborator-elem'>{request.userId.firstName + " " + request.userId.lastName}</div>
                                        <div className='email-collaborator-elem'>{request.userId.email}</div>
                                    </div>
                                    <div className='answer-asking-collabs'>
                                        <div className='delete-contact-collaborators' onClick={() => acceptCollaborator(request.id)}><img src='check.svg'/></div>
                                        <div className='delete-contact-collaborators' onClick={() => refuseCollaborator(request.id)}><img src='cross.svg' className="cross-refuse-collab"/></div>
                                    </div>
                                </div>
                            );
                        })}
                     </div>
                </div>
            </div>
        </div>
    );
};

export default Collaborators;